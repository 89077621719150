<template>
  <v-app>
    <v-row align-content="center">
      <v-col align="center">
        <v-card outlined max-width="400">
          <v-card-title>本人認証 (SMS)</v-card-title>
          <v-card-text>
            <v-form ref="form1" v-model="valid1">
              <v-container>
                <v-text-field
                  v-model="credentials.phone"
                  type="number"
                  inputmode="numeric"
                  label="電話番号を入力 (例 08012345678)"
                  :rules="[rules.phone]"
                  required
                />
                <!-- recaptcha -->
                <div id="recaptcha-container" class="mt-4 mb-4"></div>
              </v-container>
            </v-form>
            <v-row justify="center">
              <v-col>
                <v-btn
                  :disabled="!valid1 || !recaptureSolved"
                  x-large
                  block
                  color="primary"
                  @click="sendCode"
                  >確認コードを送信</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- 確認ダイアログ -->
    <v-dialog
      v-model="confirmInfo.show"
      :max-width="confirmInfo.maxWidth"
      persistent
    >
      <v-form ref="form" v-model="valid2">
        <v-card>
          <v-card-title v-html="confirmInfo.title" />
          <v-card-text>
            <v-container>
              <v-text-field
                v-model="credentials.code"
                type="number"
                inputmode="numeric"
                label="確認コードを入力"
                :rules="[rules.code]"
                required
              />
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="confirmInfo.textNegative"
              class="mr-3"
              text
              @click="confirmInfo.doCancel"
            >
              {{ confirmInfo.textNegative }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="confirmInfo.doPositive"
              :disabled="!valid2"
            >
              {{ confirmInfo.textPositive }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- snack -->
    <v-snackbar v-model="snack" :timeout="snackTimeout" :color="snackColor">
      {{ snackText }}
    </v-snackbar>

    <v-overlay v-show="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import firebase from "firebase/compat/app";
import { getAuth, RecaptchaVerifier, PhoneAuthProvider } from "firebase/auth";
import { parsePhoneNumber } from "awesome-phonenumber";

export default {
  name: "AuthPhone",
  data: () => ({
    loginStatus: "",
    loading: false,
    showPassword: false,
    valid1: true,
    valid2: true,
    credentials: {},
    rules: {
      phone: (v) => !!v || "必須入力です",
      code: (v) => !!v || "必須入力です",
    },

    recaptureSolved: false,
    recaptchaVerifier: null,
    recaptchaId: -1,
    verificationId: null,

    // 確認ダイアログ
    confirmInfo: {
      show: false,
      maxWidth: 300,
      contents: "",
      textPositive: "",
      textNegative: "",
      doPositive: () => {},
      doCancel: () => {},
    },

    // snack
    snack: false,
    snackTimeout: -1,
    snackColor: "",
    snackText: "",
  }),
  async created() {},
  async mounted() {
    this.initRecaptcha();
  },
  computed: {
    ...mapGetters(["progress"]),
  },
  methods: {
    ...mapActions([
      "showProgress",
      "hideProgress",
      "sendAuthCode",
      "verifyAuthCode",
    ]),
    async initRecaptcha() {
      // recaptcha
      getAuth().languageCode = "jp";
      this.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          // size: "invisible",
          callback: () => {
            // reCAPTCHA solved
            this.recaptureSolved = true;
          },
        },
        getAuth()
      );
      this.recaptchaId = await this.recaptchaVerifier.render();
    },
    /**
     * 確認コード送信
     */
    async sendCode() {
      this.showProgress();
      try {
        await this._sendCode();

        // 送信 OK ならダイアログ表示 (コード入力)
        this.showVerifyCodeDialog();
      } catch (e) {
        // console.error(e);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "送信に失敗しました。もう一度お願いします。";
      } finally {
        this.credentials.phone = null; // クリア (連打防止)
        this.hideProgress();
      }
    },
    /**
     * 電話番号認証を既存アカウントにリンク
     */
    async verifyCode() {
      this.showProgress();
      try {
        await this._verifyCode();

        //TODO ユーザデータ作成 (DB)

        // 確認コード認証 OK ならメニュー一覧へ遷移
        this.$router.replace("/");
      } catch (e) {
        // console.error(e);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "認証に失敗しました。もう一度お願いします。";
      } finally {
        this.hideProgress();
      }
    },
    /**
     * 確認ダイアログ
     */
    showVerifyCodeDialog() {
      this.confirmInfo = {
        show: true,
        maxWidth: 400,
        title: "届いた確認番号を入力してください",
        textPositive: "送信する",
        textNegative: "キャンセル",
        doPositive: async () => {
          this.confirmInfo.show = false; // とじる(即)

          // コード認証
          this.verifyCode();
        },
        doCancel: () => {
          this.confirmInfo.show = false; // とじる
        },
      };
    },
    async _sendCode() {
      // SMS 送信 (本人認証)
      const phoneNumber = this._getPhoneNumber();
      const provider = new PhoneAuthProvider(getAuth());
      this.verificationId = await provider.verifyPhoneNumber(
        phoneNumber,
        this.recaptchaVerifier
      );
      // console.log("verificationId", this.verificationId);
    },
    async _verifyCode() {
      // 電話番号認証を既存アカウントにリンク
      // (前提＝アカウント作成済み)
      const verificationCode = this._getVerificationCode();
      const phoneCredential = PhoneAuthProvider.credential(
        this.verificationId,
        verificationCode
      );
      return firebase.auth().currentUser.linkWithCredential(phoneCredential);
    },
    _getPhoneNumber() {
      const inputPhoneNumber = this.credentials.phone;
      const pn = parsePhoneNumber(inputPhoneNumber, "JP");
      let phoneNumber = pn.getNumber(); // -> '+8112345678'
      if (process.env.VUE_APP_NODE_ENV === "local") {
        getAuth().settings.appVerificationDisabledForTesting = true;
        const TEST_PHONE_NUMBER = "+16505551234";
        phoneNumber = TEST_PHONE_NUMBER;
      }
      return phoneNumber;
    },
    _getVerificationCode() {
      let verificationCode = this.credentials.code;
      if (process.env.VUE_APP_NODE_ENV === "local") {
        const TEST_PHONE_CODE = "123456";
        verificationCode = TEST_PHONE_CODE;
      }
      return verificationCode;
    },
  },
};
</script>
